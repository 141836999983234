@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply text-gray-900 bg-white font-sans;
  }
}

@layer components {
  .hero-section {
    @apply relative min-h-screen flex items-center overflow-hidden;
  }

  .hero-background {
    @apply absolute inset-0;
    background: linear-gradient(to right, #1e40af, #1e3a8a, #1e40af);
  }

  .hero-overlay {
    @apply absolute inset-0 opacity-20;
    background: radial-gradient(
      circle at center,
      theme('colors.blue.500'),
      theme('colors.blue.900'),
      theme('colors.blue.900')
    );
  }

  .btn-primary {
    @apply bg-blue-900 text-white px-8 py-3 rounded-full font-medium 
           hover:bg-blue-800 transition-colors inline-flex items-center;
  }

  .btn-secondary {
    @apply border-2 border-blue-900 text-blue-900 px-8 py-3 rounded-full font-medium 
           hover:bg-blue-900 hover:text-white transition-colors inline-flex items-center;
  }

  .section-title {
    @apply text-3xl md:text-4xl font-bold mb-6;
  }

  .container-custom {
    @apply container mx-auto px-4 max-w-7xl;
  }

  .image-placeholder {
    @apply bg-gradient-to-br from-gray-200 to-gray-300 w-full h-full;
  }

  .card {
    @apply bg-white rounded-xl shadow-lg p-6 transition-all duration-300;
  }
}

@layer utilities {
  .text-gradient {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400;
  }

  .hover-scale {
    @apply hover:scale-105 transition-transform duration-300;
  }

  .animate-fade-in {
    animation: fadeIn 0.5s ease-in forwards;
  }

  .animate-slide-up {
    animation: slideUp 0.5s ease-out forwards;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
